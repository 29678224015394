'use strict';

/* home.js -- Components for home template specific items such as Event Listing */

var Handlebars = require('handlebars');


// Register event handlers here (like jQuery load)
function _registerEventHandlers() {

    $(document).ready(function() {

        // No longer on home page
        //$.ajax({
        //    url: '/umbraco/api/events/get',
        //    dataType: 'json',
        //    success: function(data) {
        //        //console.log('AJAX returned data.')
        //        var source = $('#events-template').html();
        //        var template = Handlebars.compile(source);
        //        var context = { event: data };
        //        $('.event-list').html(template(context));
        //        $(".loading-events").hide();
        //    },
        //    error: function() {
        //        //console.log('AJAX error.')
        //        $('.no-events-returned').show();
        //        $(".loading-events").hide();
        //    }
        //});

    });

}

_registerEventHandlers();